/*  ==========================================================================
    TYPOGRAPHY

    INFO:
    - mind the ordering
    ========================================================================== */

/*
 * PRESETS
 */

// highlighting
@mixin typo--highlighting($clr-accent) {
  b,
  strong {
    font-weight: $typo__fw--bold;
  }
  s,
  u {
    text-decoration-color: $clr-accent;
  }
  sub,
  sup {
    font-size: 60%;
  }
}

// inlink
@mixin typo--inlink($clr) {
  a {
    color: $clr;
    &:visited {
      color: $clr;
    }
    &:hover {
      color: rgba($clr, 0.5);
    }
  }
}

// disallow lists
@mixin typo--list-lock() {
  ol,
  ul {
    display: none !important;
  }
}

// disallow tables
@mixin typo--table-lock() {
  table,
  .bn-table-wrap {
    display: none !important;
  }
}

/*
 * TEXT TYPES
 */

// headline
@mixin typo--headline($clr) {
  color: $clr;
  font-family: $typo__ff--secondary;
  font-size: $typo__fs--headline;
  font-weight: $typo__fw--bold;
  text-transform: uppercase;

  /*
   * RESPONSIVE BEHAVIOR
   */

  // tablet
  @media #{$mq__tablet} {
    font-size: $typo__fs--headline-tablet;
  }
  // phablet
  @media #{$mq__phablet} {
    font-size: $typo__fs--headline-phablet;
  }
  // phone
  @media #{$mq__phone} {
    font-size: $typo__fs--headline-phone;
  }
}

// subheadline
@mixin typo--subheadline($clr) {
  color: $clr;
  font-family: $typo__ff--secondary;
  font-size: $typo__fs--subheadline;
  font-weight: $typo__fw--regular;
  text-transform: uppercase;

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phablet
  @media #{$mq__phablet} {
    font-size: $typo__fs--subheadline-phablet;
  }
  // phone
  @media #{$mq__phone} {
    font-size: $typo__fs--subheadline-phone;
  }
}

// paragraph
@mixin typo--paragraph($clr,$clr-accent) {
  @include typo--highlighting($clr-accent);
  @include typo--inlink($clr-accent);
  color: $clr;
  font-size: $typo__fs--text;
  font-weight: $typo__fw--regular;
  line-height: 1.8;
  * {
    font-size: 1.0em;
  }
}

// unordered list
@mixin typo--ul($clr,$clr-accent) {
  @include typo--highlighting($clr-accent);
  @include typo--inlink($clr-accent);
  color: $clr;
  font-size: $typo__fs--text;
  line-height: 1.8;
  list-style-type: none;
  padding-left: 0;
  * {
    font-size: 1.0em;
  }
  li {
    padding-left: 25px;
    position: relative;
    &:before {
      color: $clr-accent;
      content: "•";
      left: 0;
      position: absolute;
      text-align: center;
      text-transform: none;
      top: 0;
      width: 25px;
    }
  }
}

// ordered list
@mixin typo--ol($clr,$clr-accent) {
  @include typo--highlighting($clr-accent);
  @include typo--inlink($clr-accent);
  color: $clr;
  font-size: $typo__fs--text;
  line-height: 1.8;
  padding-left: 25px;
  * {
    font-size: 1.0em;
  }
}

// table
@mixin typo--table($clr,$clr-accent) {
  @include typo--highlighting($clr-accent);
  @include typo--inlink($clr-accent);
  border-collapse: collapse;
  width: 100%;
  tr {
    th,
    td {
      border: 0;
      border-bottom: 1px solid $clr__ci--primary;
      line-height: 1.8;
      padding: 5px 10px;
      * {
        font-size: 1.0em;
      }
    }
    th {
      background-color: $clr__ci--primary;
      color: $clr__typo--secondary;
      font-size: calc(#{$typo__fs--text} + 0.2em);
      font-weight: $typo__fw--regular;
      text-align: left;
      a {
        color: $clr__typo--secondary;
        text-decoration: underline;
        &:visited {
          color: $clr__typo--secondary;
        }
        &:hover {
          color: $clr__typo--secondary;
        }
      }
    }
    td {
      color: $clr;
      font-size: $typo__fs--text;
    }
    &:hover {
      td {
        background-color: rgba($clr__typo--primary,0.2);
      }
    }
  }
}

/*
 * BLOCKS
 */

// table wrap
@mixin typo--table-wrap($clr,$clr-accent) {
  min-height: 100px;
  position: relative;
  table {
    @include typo--table($clr,$clr-accent);
  }
  > div {
    @include scrollbar($clr__scrollbar,5px);
    overflow-x: hidden;
  }
  .bn-table-wrap__overlay {
    background-color: rgba($clr__black,0.2);
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
    .bn-icon {
      color: $clr__white;
      font-size: 5.0em;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &:hover {
    .bn-table-wrap__overlay {
      display: none !important;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phablet
  @media #{$mq__phablet} {
    table {
      min-width: 768px;
    }
    > div {
      overflow-x: auto;
    }
    .bn-table-wrap__overlay {
      display: block;
    }
  }
}

// rte (styling for rich text editor outputs)
@mixin typo--rte($clr,$clr-accent) {
  > * {
    + * {
      margin-top: 10px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @include typo--paragraph($clr,$clr-accent);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $typo__fw--bold;
  }
  ul {
    @include typo--ul($clr,$clr-accent);
  }
  ol {
    @include typo--ol($clr,$clr-accent);
  }
  .bn-table-wrap,
  .bn-scrabble__table_wrap {
    @include typo--table-wrap($clr,$clr-accent);
  }
}

// no result
@mixin typo--no-result() {
  color: $clr__error;
  display: inline-block;
  font-size: 1.6em;
  font-style: italic;
  text-align: center;
  width: 100%;
}

// quote
@mixin typo--quote($clr) {
  @include typo--list-lock();
  @include typo--table-lock();
  color: $clr;
  font-size: 2.5em;
  line-height: 1.6;

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phablet
  @media #{$mq__phablet} {
    font-size: 2.0em;
  }
  // phone
  @media #{$mq__phone} {
    font-size: 1.8em;
  }
}

// quote author
@mixin typo--quote-author($clr) {
  @include typo--inlink($clr);
  color: $clr;
  font-size: 1.6em;
  &:before,
  &:after {
    content: '-';
    display: inline-block;
  }
  &:before {
    margin-right: 5px;
  }
  &:after {
    margin-left: 5px;
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // phablet
  @media #{$mq__phablet} {
    font-size: 1.4em;
  }
  // phone
  @media #{$mq__phone} {
    &:before,
    &:after {
      display: none;
    }
  }
}

// form
@mixin typo--form($clr) {
  input[type='text'],
  select,
  textarea {
    color: $clr;
    font-size: $typo__fs--text;
  }
}